import React from "react";
import { Image } from "react-bootstrap";

export default function FormHeader({ formTitle, children, logo, titleColor, formParagraphs }) {
  return (
    <div>
      <div style={{ marginBottom: '40px' }}>
        <div className="form-header">
          <div dangerouslySetInnerHTML={{ __html: formTitle }} />
          <Image className="form-logo" src={logo} alt="Optistartech" />
        </div>
        <div style={{ marginTop: '30px' }}>
          {
            formParagraphs ?
              formParagraphs.map((item) => {
                return (
                  <div key={item.id} className="custom-paragraphs" dangerouslySetInnerHTML={{ __html: item.text }} />
                )
              })
              :
              null
          }
        </div>
      </div>
      {children}
    </div>
  );
}
