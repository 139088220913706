import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import FormComponent from "./FormComponent";
import SuccessMessage from "./SuccessMessage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/thankyou" element={<SuccessMessage />} />
        <Route path="/" element={<FormComponent />} />
      </Routes>
    </Router>
  );
}
