import React, { useContext, useState } from 'react'
import { FormContext } from '../Context/FormContext'

const Input = React.memo(({ field_id, field_label, field_value, field_mandatory, field_validation, field_response_errors }) => {
    const { handleChange } = useContext(FormContext)
    const [currValue, setCurrValue] = useState(field_value ? field_value[0].trim() : '')

    function valueChanged(event) {

        setTimeout(() => {
            field_response_errors = null
        }, 1000);

        setCurrValue(event.target.value)
        handleChange(field_id, { target: { value: [event.target.value] } })
    }

    return (
        <div className="row">
            <div className="input-field">
                <input
                    id={field_id}
                    type="text"
                    className={currValue.length > 0 ? "validate valid" : "validate"}
                    value={currValue}
                    onChange={event => valueChanged(event)}
                />
                <label className={currValue.length > 0 ? "active" : ''} htmlFor={field_id}>{field_label}{field_mandatory === "yes" ? '*' : ''}</label>
            </div>
            {
                field_validation.map((item) => {
                    if (item["show_error"] === "true")
                        return <p id={item.validation_id} key={item.validation_id} className="text-error error-element">{item["error_message"]}</p>
                    else
                        return null
                })
            }

            {
                field_response_errors ?
                    field_response_errors.map((item) => {
                        return <p id={item.id} key={item.id} className="text-error">{item.error_message}</p>
                    })
                    :
                    null
            }

        </div>
    )
})

export default Input