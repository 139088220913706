export default function GeneralStyling(body_bg_color, paragraphs_color, separators_color) {

    var css = `
    
    body {
        background-color: ${body_bg_color};
    }

    .custom-paragraphs {
        color: ${paragraphs_color};
    }

    .custom-separators {
        margin: 2rem 0;
        padding-bottom: 20px;
        border-bottom: 2px solid ${separators_color};
        color: ${separators_color};
    }
    `

    var style = document.createElement('style');

    if (style.styleSheet) {
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }

    document.getElementsByTagName('head')[0].appendChild(style);
}