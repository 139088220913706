export function checkRequired(formElements) {
    let validate = true;
    const newElements = [...formElements];

    newElements.forEach((field) => {
        if (field.field_type !== "formParagraph" && field.field_type !== "separator") {
            if (field.field_mandatory === "yes") {
                if (field.field_other) {
                    if ((field.field_other["field_value"].trim().length < 1 && (field.field_other).is_visible === true)) {
                        validate = false;
                        field.field_validation[0]["show_error"] = "true"
                    } else if ((field.field_value).length < 1 && (field.field_other).is_visible === false) {
                        validate = false;
                        field.field_validation[0]["show_error"] = "true"
                    } else if (!field.field_value) {
                        validate = false;
                        field.field_validation[0]["show_error"] = "true"
                    } else {
                        field.field_validation[0]["show_error"] = "false"
                    }
                }
                else {
                    if (!field.field_value || (field.field_type === "checkbox" && field.field_value[0] === "false") || (field.field_value).length === 0) {
                        field.field_validation[0]["show_error"] = "true"
                        validate = false;
                    }
                    else {
                        field.field_validation[0]["show_error"] = "false"
                    }

                    if (field.field_type === "text" || field.field_type === "textarea" || field.field_type === "select" || field.field_type === "lookup" || field.field_type === "date") {
                        if (field.field_value[0]) {
                            if (field.field_value[0].trim().length < 1) {
                                field.field_validation[0]["show_error"] = "true"
                                validate = false
                            }
                        }

                        if (!field.field_value[0]) {
                            field.field_validation[0]["show_error"] = "true"
                            validate = false
                        }
                    }
                }
            }
        }
    })

    return [validate, newElements]
}