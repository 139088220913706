import React, {
    useContext, useState, useRef
} from 'react'
import { FormContext } from '../Context/FormContext'
export default React.memo(function MultifileInput({ field_id, field_label, field_mandatory, field_file_type, field_single_size_bytes, field_max_size_bytes, field_validation, field_response_errors }) {
    const { handleChange } = useContext(FormContext)
    const [sizeError, setSizeError] = useState()
    const [sumSizeError, setSumSizeError] = useState()
    const [typeError, setTypeError] = useState()
    const ref = useRef()

    function valueChanged(event) {
        if (event.target.files[0]) {

            const files = event.target.files
            const newFiles = []

            if (!!(validateFileSize(files) & validatesFilesSize(files) & validateFilesType(files))) {
                for (let index = 0; index < files.length; index++) {
                    const file = files[index];
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = event => {
                        newFiles.push({
                            field_value: event.target.result,
                            field_file_type: file.type,
                            field_file_name: file.name
                        })
                    };
                }
                handleChange(field_id, { target: { value: newFiles } })
            }
        }
    }

    function validateFilesType(files) {

        let isValid = true;
        const fileTypes = field_file_type.split(',')

        Array.from(files).forEach(element => {
            if (!fileTypes.includes(element.type)) {
                isValid = false
            }
        });


        if (!isValid) {
            setTypeError(`Wrong file extension, allowed extensions: ${getAllowedFileType(fileTypes)}`)
            // clear input
            ref.current.value = ""
        }

        return isValid
    }

    function validateFileSize(files) {
        let isValid = true;
        let invalidFileName = []

        Array.from(files).forEach(element => {
            if (element.size > field_single_size_bytes) {
                isValid = false
                invalidFileName.push(element.name)
            }
        });

        if (!isValid) {
            const fileNames = invalidFileName.toString(invalidFileName);

            if (invalidFileName.length > 1) {
                setSizeError(`Files: ${fileNames}
                are too big, maximum file size is : ${bytesToMB(field_single_size_bytes)} MB`)
            } else {
                setSizeError(`File: ${fileNames}
                is too big, maximum file size is : ${bytesToMB(field_single_size_bytes)} MB`)
            }

            ref.current.value = ""
        }

        return isValid;
    }

    function validatesFilesSize(files) {
        let isValid = true;
        let filesSize = 0;

        Array.from(files).forEach(element => {
            filesSize = filesSize + element.size
        });
        if (filesSize > field_max_size_bytes) {
            isValid = false;
            setSumSizeError(`Files exceeded maximum size, allowed size is: ${bytesToMB(field_max_size_bytes)} MB`)
        }

        return isValid
    }

    function getAllowedFileType(allowedFileTypes) {
        const fileTypes = []
        if (allowedFileTypes.length > 1) {
            allowedFileTypes.forEach(item => {
                let tmp = item.split('/')
                fileTypes.push(tmp[1])
            })
        } else {
            let tmp = allowedFileTypes[0].split('/')
            fileTypes.push(tmp[1])
        }
        return fileTypes.join(', ')
    }

    function bytesToMB(size) {
        return (size / (1024 * 1024)).toFixed(0);
    }

    return (
        <div className="mb-3">
            <label className="form-label">{field_label} {field_mandatory === "yes" ? '*' : ''}</label>
            <div className="file-field input-field">
                <div className="btn">
                    <span>File</span>
                    <input type="file"
                        ref={ref}
                        multiple={true}
                        accept={field_file_type}
                        onChange={event => valueChanged(event)}
                        onClick={() => {
                            setTypeError(null)
                            setSizeError(null)
                            setSumSizeError(null)
                        }}
                    />
                </div>
                <div className="file-path-wrapper">
                    <input className="file-path validate" type="text" placeholder="Upload one or more files" />
                </div>
            </div>
            {
                field_validation.map((item) => {
                    if (item["show_error"] === "true")
                        return <p id={item.validation_id} key={item.validation_id} className="text-error error-element">{item["error_message"]}</p>
                    else
                        return null
                })
            }
            {typeError ? <div style={{ color: 'rgb(255, 38, 38)', marginTop: '0.25rem' }}>{typeError}</div> : null}
            {sizeError ? <div style={{ color: 'rgb(255, 38, 38)', marginTop: '0.25rem' }}>{sizeError}</div> : null}
            {sumSizeError ? <div style={{ color: 'rgb(255, 38, 38)', marginTop: '0.25rem' }}>{sumSizeError}</div> : null}
            {
                field_response_errors ?
                    field_response_errors.map((item) => {
                        return <p key={item.id} className="text-error">{item.error_message}</p>
                    })
                    :
                    null
            }
        </div>
    )
})
