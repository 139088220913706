import React from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from "react-router-dom";
import FormHeader from './UI/FormHeader'
import { Button } from "react-bootstrap";
import Container from './UI/Container';

export default function SuccessMessage() {
    const { state } = useLocation()
    let navigate = useNavigate();
    return (
        <Container>
            <div className="form-container">
                <FormHeader formTitle={state.formTitle} logo={state.logo} formParagraphs={state.formHeaderParagraphs}>
                    <h4 style={{ textAlign: 'center', marginTop: '20px' }}>{state.thankyouPage.title}</h4>
                    <h5 style={{ textAlign: 'center', marginTop: '20px' }}>{state.thankyouPage.message}</h5>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Button
                            style={{ marginTop: '20px' }}
                            className="btn-large"
                            onClick={() => {
                                navigate("/")
                            }}
                        >
                            Go back to form
                        </Button>
                    </div>
                </FormHeader>
            </div>
        </Container>
    )
}
