import React, { useContext, useRef, useState } from 'react'
import { FormContext } from '../Context/FormContext'

export function validateFileType(uploadedFileType, field_file_type) {
    if (field_file_type.length < 1)
        return true

    const fileTypes = field_file_type.split(',')

    if (fileTypes.includes(uploadedFileType)) {
        return true
    } else {
        return false
    }
}

export function validateFileSize(uploadedfileSize, field_max_size_bytes) {
    if (uploadedfileSize < field_max_size_bytes)
        return true
    else
        return false
}

export function bytesToMB(size) {
    return (size / (1024 * 1024)).toFixed(0);
}

const Input = React.memo(({ field_id, field_label, field_mandatory, field_file_type, field_max_size_bytes, field_validation, field_response_errors }) => {
    const { handleChange } = useContext(FormContext)
    const [sizeError, setSizeError] = useState()
    const [typeError, setTypeError] = useState()
    const ref = useRef()
    function valueChanged(event) {
        let isValid = true
        if (event.target.files[0]) {
            const fileReader = new FileReader();
            const fileType = event.target.files[0].type
            const fileSize = event.target.files[0].size
            const fileName = event.target.files[0].name

            fileReader.readAsDataURL(event.target.files[0])
            fileReader.onload = event => {

                if (!validateFileType(fileType, field_file_type)) {
                    isValid = false
                    setTypeError(`Wrong file extension, allowed extensions: ${field_file_type}`)
                }

                if (!validateFileSize(fileSize, field_max_size_bytes)) {
                    isValid = false
                    setSizeError(`File is too big, maximum file size is : ${bytesToMB(field_max_size_bytes)} MB`)

                }

                if (isValid) {
                    handleChange(field_id, {
                        target: {
                            value: [{
                                field_value: event.target.result,
                                field_file_type: fileType,
                                field_file_name: fileName
                            }]
                        }
                    })
                } else {
                    ref.current.value = ""
                }
            };
        }
    }

    return (
        <div className="row">
            <label className="form-label">{field_label} {field_mandatory === "yes" ? '*' : ''}</label>
            <div className="file-field input-field">
                <div className="btn">
                    <span>File</span>
                    <input
                        type="file"
                        ref={ref}
                        accept={field_file_type}
                        onClick={() => {
                            setTypeError(null)
                            setSizeError(null)
                        }}
                        onChange={event => valueChanged(event)}
                    />
                </div>
                <div className="file-path-wrapper">
                    <input className="file-path validate" type="text" />
                </div>
            </div>
            {
                field_validation.map((item) => {
                    if (item["show_error"] === "true")
                        return <p id={item.validation_id} key={item.validation_id} className="text-error error-element">{item["error_message"]}</p>
                    else
                        return null
                })
            }
            {typeError ? <div style={{ color: 'rgb(255, 38, 38)', marginTop: '0.25rem' }}>{typeError}</div> : null}
            {sizeError ? <div style={{ color: 'rgb(255, 38, 38)', marginTop: '0.25rem' }}>{sizeError}</div> : null}
            {
                field_response_errors ?
                    field_response_errors.map((item) => {
                        return <p key={item.id} className="text-error">{item.error_message}</p>
                    })
                    :
                    null
            }
        </div>
    )
})

export default Input