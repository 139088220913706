import React, { useContext, useState } from 'react'
import { FormContext } from '../Context/FormContext'
const Checkbox = React.memo(({ field_id, field_label, field_mandatory, field_validation, field_response_errors }) => {
    const { handleChange } = useContext(FormContext)
    const [isChecked, setIsChecked] = useState(false)

    return (
        <div>
            <p>
                <label>
                    <input type="checkbox" className="filled-in" checked={isChecked}
                        onChange={event => {
                            setIsChecked((prevState) => {
                                return !prevState
                            })
                            handleChange(field_id, { target: { value: [`${event.target.checked}`] } })
                        }}
                    />
                    <span>{field_label} {field_mandatory === "yes" ? '*' : ''}</span>
                </label>
            </p>
            {
                field_validation.map((item) => {
                    if (item["show_error"] === "true")
                        return <p id={item.validation_id} key={item.validation_id} className="text-error error-element">{item["error_message"]}</p>
                    else
                        return null
                })
            }
            {
                field_response_errors ?
                    field_response_errors.map((item) => {
                        return <p key={item.id} className="text-error">{item.error_message}</p>
                    })
                    :
                    null
            }
        </div>
    )
});

export default Checkbox